import React from "react";
import Iframe from "react-iframe";

export const IframeScreen = ({ link = '' }) => {

  return(
    <>
      <Iframe
        url={link}
        name={window.location.hostname}
        width="100%"
        height={window.innerHeight}
        id="iframe"
        className="iframe"
        display="initial"
        position="relative"
        loading="eager"
      />
    </>
  )
}

export default IframeScreen;
