import './App.css';
import IframeScreen from './components/Iframe.screen';

function App() {

  return (
    <div className="App">
      <IframeScreen link={`https://app.leadific.io/?ref=${window?.location?.hostname}`} />
    </div>
  );
}

export default App;
